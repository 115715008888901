import '../../App.css';
import '@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css';
import Header from '../common/Header';
import Footer from '../common/Footer';
import Sidebar from '../common/Sidebar';
import PrivacyPolicyPageContent from './PrivacyPolicyPageContent'


function PrivacyPolicyPage() {

  return (
    <div className="App">
      <Header/>
      <div className='body'>
        <div className='subBody'>
            <PrivacyPolicyPageContent/>
        </div>
      </div>
      <Footer/>
    </div>
  );
}

export default PrivacyPolicyPage;
