import "./ComingSoon.css";

function ComingSoonContent() {
  return (
    <div className="coming-soon-container">
      <div className="coming-soon-message">
        <label>Coming Soon</label>
      </div>
    </div>
  );
}
  
export default ComingSoonContent;