import axios from "axios";
import Cookies from 'js-cookie';
import { axiosGet, axiosPost } from "../common";

export const createDiscount = (json) => {
  const url = '/api/discount/create';
  return axiosPost(url, json);
};

export const createBundleDiscount = (json) => {
  const url = '/api/discount/bundle/create';
  return axiosPost(url, json);
};


export const fetchAllDiscounts = (json) => {
  const url = '/api/discount/fetchAll';
  return axiosPost(url, json);
};

export const fetchAllBundleDiscounts = (json) => {
  const url = '/api/discount/bundle/fetchAll';
  return axiosPost(url, json)
};

export const deleteBundleDiscountById = (json) => {
  const url = '/api/discount/bundle/archive';
  return axiosPost(url, json);
};