import AnalyticsStatistics from "./AnalyticsStatistics";
import "./Analytics.css"

function Analytics() {
    var dataArray = 
    [
        {
            title: "Total Comments",
            mainData: 0,
            subTitle: "Keyword",
            subData: 0
        },
        {
            title: "Bid Comments",
            mainData: 0,
            subTitle: "Keyword",
            subData: 0
        },
        {
            title: "Unique Bidders",
            mainData: 0,
            subTitle: "Bidders",
            subData: 0
        },
        {
            title: "Items Sold",
            mainData: 0,
            subTitle: "Paid",
            subData: 0
        },
        {
            title: "Amount",
            mainData: 0,
            subTitle: "Paid",
            subData: 0
        }
    ]
    return (
      <div className="Analytics">
        <div className="Analytics-Title">
            Analytics <label>(Coming soon)</label>
        </div>
        <div className="AnalyticsStatistic-Holder">
            {dataArray.map((data, index) => (
                <AnalyticsStatistics key={index} data={data} />
            ))}
        </div>
      </div>
    );
  }
  
export default Analytics;