import React from 'react';
import "./PrivacyPolicyPageContent.css"

function PrivacyPolicyPageContent() {
  return (
    <div className="privacy-policy-container">
      <h1>Privacy Policy</h1>
      
      <section>
        <h2>Muezza Privacy Policy</h2>
        <p>
          This Privacy Policy (the "Policy") outlines the terms between Muezza and you, the user of our website and services. By accessing and using our website, you confirm that you are at least eighteen (18) years old and agree to the terms outlined in this Policy, which may be updated periodically.
        </p>
        <p>
          Muezza is committed to safeguarding your privacy and personal data. This Policy explains how we collect, use, disclose, and manage your data, and applies to all personal data under our control.
        </p>
      </section>

      <section>
        <h2>1. GENERAL</h2>
        <p>
          a. Muezza may collect personal data that identifies you (such as names, contact information, addresses) when you use our platform. We are required to comply with all relevant laws, including the Payment Services Act (No. 2 of 2019).
        </p>
        <p>
          b. We may also gather data from your device, including information about your operating system, hardware version, settings, and connection details like ISP or mobile operator, browser type, and IP address.
        </p>
        <p>
          c. Additionally, we might collect other information directly from you, such as data from your interactions on our website or any communication with us.
        </p>
      </section>

      <section>
        <h2>2. INFORMATION YOU PROVIDE TO Muezza</h2>
        <p>
          a. When providing data through our platform, you confirm that the individual whose data is collected has been informed about its use and has consented to this collection, processing, and disclosure.
        </p>
        <p>
          b. If the individual withdraws their consent, you must inform us immediately, as this may affect the services we can provide.
        </p>
        <p>
          c. Any consent related to the data will remain valid despite the individual's death, incapacity, or account termination, subject to applicable laws.
        </p>
      </section>

      <section>
        <h2>3. DATA RETENTION, USE, AND STORAGE</h2>
        <p>
          a. Muezza may collect, store, process, and use the data in accordance with the law.
        </p>
        <p>
          b. The data may be used for various business purposes, including managing accounts, verifying identities, responding to requests, facilitating transactions, analyzing business performance, marketing, compliance with laws, and fraud prevention.
        </p>
        <p>
          c. We may retain data as needed for record-keeping and business operations.
        </p>
      </section>

      <section>
        <h2>4. DATA DISCLOSURE</h2>
        <p>
          a. Muezza may disclose collected data to agents, contractors, or third-party service providers associated with our business operations, or any related group companies.
        </p>
        <p>
          b. We may also disclose data if required by law or to comply with legal, regulatory, or government requests.
        </p>
      </section>

      <section>
        <h2>5. MARKETING COMMUNICATIONS</h2>
        <p>
          a. With your consent, you may receive marketing communications such as newsletters, emails, and text messages about Muezza’s services, offers, and updates.
        </p>
        <p>
          b. You can change your marketing communication preferences by contacting us.
        </p>
        <p>
          c. Important service-related information will be sent to you even if you opt out of marketing communications.
        </p>
      </section>

      <section>
        <h2>6. USE OF COOKIES</h2>
        <p>
          Our website may use cookies to enhance your experience. You can block or delete cookies through your browser settings, but this might affect your ability to use certain features of the site.
        </p>
      </section>
    </div>
  );
}

export default PrivacyPolicyPageContent;
