import React from "react";
import "./Integration.css";
import { removeShopifyIntegration } from "../../service/integration/shopify";
import { useNavigate } from "react-router-dom";


function IntegratedTab(props) {
    const navigate = useNavigate();

    return (
        <div className="Integrated-Tab-Parent">
            <div className="Integrated-Tab">
                <div className="Integrated-Store-Details">
                    <div style={{fontWeight:"bold"}}>Store Name: {props.storeName ?? "Store Name"}</div>
                    <div>FacebookId: {props.facebookId ?? "FacebookId"}</div>
                </div>
                <div onClick={() => removeShopifyIntegration(navigate)}className="Integrated-Store-Remove">
                    Remove
                </div>
            </div>
        </div>
        
    );
}

export default IntegratedTab;
