import React, { useState } from 'react';
import Paper from '@mui/material/Paper';
import { DataTypeProvider, EditingState } from '@devexpress/dx-react-grid';
import {
  Grid,
  Table,
  TableEditColumn,
  TableHeaderRow,
  TableInlineCellEditing,
} from '@devexpress/dx-react-grid-material-ui';
import { useEffect } from 'react';
import { discountKey } from '../../constants/discounts';
import { deleteBundleDiscountById } from '../../service/discount/discount';

const getRowId = row => row.id;

const FocusableCell = ({ onClick, ...restProps }) => (
  <Table.Cell {...restProps} tabIndex={0} onFocus={onClick} />
);

const deleteDiscount = async (id) => {
  try {
      await deleteBundleDiscountById({id: id});
  } catch (error) {
      console.error('Error deleting discount:', error);
      // Handle error if needed
  }
}


export default function PromotionsTable(props){
  const [columns] = useState([
    { name: 'discountTitle', title: 'Title' },
    { name: 'variantCount', title: 'Discount Applied To' },
    { name: 'bundleQty', title: 'Bundle Qty' },
    { name: 'bundlePrice', title: 'Bundle Price' },
    { name: 'usageCount', title: 'Usage Count' }
  ]);
  const [rows, setRows] = useState(props.data);
  const [editingCells, setEditingCells] = useState([]);
  const commitChanges = ({ added, changed, deleted }) => {
    let changedRows;
    if (deleted) {
        //Update backend also
        var deletedRow = rows.find(row => deleted[0]===row.id);
        var discountId = deletedRow.id;
        deleteDiscount(discountId);

        let totalQty = 5;
        const deletedSet = new Set(deleted);
        changedRows = rows.filter(row => !deletedSet.has(row.id));
        let idValue = 1;
        changedRows.forEach(row => {
          row.id = idValue;
          if(idValue<=totalQty){
            row.status='Pending2 Payment'
          }
          idValue++;
        })
    }

    setRows(changedRows);
  };

  const addEmptyRow = () => commitChanges({ added: [{}] });

  const FieldFormatter = ({ value }) => (
    <b style={{ color: 'darkblue' }}>
      {value} variants
    </b>
  );

  const FieldTypeProvider = props => (
    <DataTypeProvider
      formatterComponent={FieldFormatter}
      {...props}
    />
  );

  const CurrencyFormatter = ({ value }) => (
    <b style={{ color: 'darkblue' }}>
      {value.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
    </b>
  );  

  const CurrencyTypeProvider = props => (
    <DataTypeProvider
      formatterComponent={CurrencyFormatter}
      {...props}
    />
  );

  useEffect(() => {
    setRows(props.data);
  }, [props.data]);

  return (
    <div style={{width: '100%'}}>
      <Paper>
        <Grid
          rows={rows}
          columns={columns}
          getRowId={getRowId}
        >
          <EditingState
            onCommitChanges={commitChanges}
            editingCells={editingCells}
            onEditingCellsChange={setEditingCells}
            addedRows={[]}
            onAddedRowsChange={addEmptyRow}
          />

          <FieldTypeProvider
            for={["variantCount"]}
          />
          <CurrencyTypeProvider
            for={["bundlePrice"]}
          />
          <Table cellComponent={FocusableCell} />
          <TableHeaderRow />
          <TableEditColumn
            showDeleteCommand
          />
        </Grid>
      </Paper>
    </div>
  );
};