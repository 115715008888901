import React from 'react';
import './Sidebar.css';

function Sidebar() {
  return (
    <aside className="sidebar">
      <nav className="sidebar-nav">
        <ul>
          <li><a href="/analytics">Analytics</a></li>
          <li><a href="/products">Products</a></li>
          <li><a href="/orders">Orders</a></li>
          <li><a href="/promotions">Promotions</a></li>
          <li><a href="/message-center">Message Center</a></li>
          <li><a href="/integration">Integration</a></li>
          <li><a href="/privacy-policy">Privacy Policy</a></li>
        </ul>
      </nav>
    </aside>
  );
}

export default Sidebar;