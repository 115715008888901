import '../../App.css';
import '@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css';
import { Link } from 'react-router-dom';
import Login from '../loginForm';
import SignUp from '../signUpForm';
import FBInit from '../../FBInit';
import Header from '../common/Header';
import Footer from '../common/Footer';
import Sidebar from '../common/Sidebar';
import Analytics from './analytics/Analytics';


function HomePage() {

  return (
    <div className="App">
      <Header/>
      <div className='body'>
        <Sidebar/>
        <div className='subBody'>
          <Analytics/>
          {/* <FBInit/>
          <h1>Booster</h1>
          <nav
            style={{
              borderBottom: "solid 1px",
              paddingBottom: "1rem",
            }}
          >
            <Link to="/home">Home</Link> |{" "}
            <Link to="/landing">Landing</Link> |{" "}
            <Link to="/expenses">Instragram Orders</Link> |{" "}
            <Link to="/subscrivedProductsPage">Start Live!</Link>
            <Login></Login>
            <SignUp></SignUp>
          </nav> */}
        </div>
      </div>
      <Footer/>
    </div>
  );
}

export default HomePage;
