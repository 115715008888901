import './BundleDiscount.css';
import '@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css';
import React from 'react';


function SelectedProduct(props) {

  return (
    <div className="selectedProductContainer">
        <div className='selectedProductContainer-details'>
            <div>{props.product.title}</div>
            <div>({props.product.variants.length} variants selected)</div>
        </div>
        <div className="selectedProductContainer-cancel-btn" onClick={""}>
            x
        </div>
        
    </div>
  );
}

export default SelectedProduct;
