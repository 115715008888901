import { useEffect } from 'react';
import './Orders.css'
import OrdersTable from './OrdersTable';
import { fetchAllOrdersByPage, syncAllOrders } from '../../service/orders/orders';
import { useState } from 'react';

function OrdersPageContent() {

    const syncAllOrdersAPICall = async () => {
        try {
            const ordersData = await syncAllOrders();
            ordersData.forEach(order => {
                var totalItems = 0;
                order.orderItems.forEach(item => {totalItems+=item.length})
                order.itemQuantity = totalItems
            })
            setOrders(ordersData)
        } catch (error) {
            console.error('Error fetching products:', error);
            // Handle error if needed
        }
    }
    const [orders, setOrders] = useState([]);
    const [totalPages, setTotalPages] = useState(1);
    const [totalCount, setTotalCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [pageSize, setPageSize] = useState(5);

    useEffect(() => {
        const fetchOrders = async () => {
            try {
                const ordersPaginateData = await fetchAllOrdersByPage({shopName:"metaautomation.myshopify.com", page: currentPage, size: pageSize});
                const totalPagesResp = ordersPaginateData.totalPages;
                const currentPageResp = ordersPaginateData.pageable.pageNumber;
                const ordersData = ordersPaginateData.content;
                ordersData.forEach(order => {
                    var totalItems = 0;
                    order.orderItems.forEach(item => {totalItems+=item.quantity})
                    order.itemQuantity = totalItems
                })
                setOrders(ordersData)
                if(totalPages !== totalPagesResp)
                    setTotalPages(totalPagesResp)
                if(currentPage !== currentPageResp)
                    setCurrentPage(currentPageResp)
                setTotalCount(ordersPaginateData.totalElements)
            } catch (error) {
                console.error('Error fetching products:', error);
                // Handle error if needed
            }
        }

        fetchOrders()
    }, [totalPages, currentPage]);

  return (
    <div className="OrdersPageContent">
        <div className="OrdersPageContent-Header">
            <div>
                Orders
            </div>
            <div className="OrdersPageContent-Header-Buttons">
                <div onClick={syncAllOrdersAPICall}>
                    Sync Shopify Orders
                </div>
            </div>
        </div>
        <div className="OrdersPageContent-Stats">
            <div>
                Today
            </div>
            <div>
                <div>Total Orders</div>
                <div>{totalCount}</div>
            </div>
            <div>
                <div>Total Amount</div>
                <div>$200</div>
            </div>
        </div>
        <OrdersTable data={orders} totalCount={totalCount} totalPages={totalPages} currentPage={currentPage} pageSize={pageSize} setCurrentPage={setCurrentPage} setPageSize={setPageSize}/>
    </div>
  );
}

export default OrdersPageContent;
