
import { axiosPost } from "../common";
import { parseJwt } from "../../utilities/jwt";

export const checkShopifyIntegration = () => {
    const jwtDetails = parseJwt();
    const facebookId = jwtDetails.attributes.id;
    const url = '/api/shopify/checkIntegration';
    return axiosPost(url, {facebookId});
};

export const removeShopifyIntegration = (navigate) => {
    const jwtDetails = parseJwt();
    const facebookId = jwtDetails.attributes.id;
    const url = '/api/shopify/removeIntegration';
    axiosPost(url, {facebookId});
    navigate("/")

};