import React, { useState } from 'react';
import Paper from '@mui/material/Paper';
import {
  Grid,
  Table,
  TableHeaderRow,
  TableSelection,
  TableColumnResizing,
  TableEditColumn
} from '@devexpress/dx-react-grid-material-ui';
import { DataTypeProvider, EditingState, IntegratedSorting, SelectionState, SortingState } from '@devexpress/dx-react-grid';
import { useEffect } from 'react';

const getRowId = row => row.id;

const FocusableCell = ({ onClick, ...restProps }) => (
    <Table.Cell {...restProps} tabIndex={0} onFocus={onClick} />
  );


export default function VariantsTable(props){
  const [columns] = useState([
    { name:"productName", title:"Title"},
    { name: 'title', title: 'Variant' },
    { name: 'quantity', title: 'Quantity' },
    { name: 'bidCode', title: 'Bid Code' }
  ]);
  const [columnWidths, setColumnWidths] = useState([
    { columnName:"productName", width: 120},
    { columnName: 'title', width: 120 },
    { columnName: 'quantity', width: 100 },
    { columnName: 'bidCode', width: 180 }
  ]);

  const [rows, setRows] = useState(props.data);
  const [sorting, setSorting] = useState([{ columnName: 'buyerName', direction: 'asc' }]);

  useEffect(() => {
    var lineItems = [];
    var orderItems = props.data.orderItems;
    orderItems.forEach(orderItem => {
        var item = {};
        item.productName = orderItem.shopifyProductVariant.productName;
        item.title = orderItem.shopifyProductVariant.title;
        item.quantity = orderItem.quantity;
        item.bidCode = orderItem.shopifyProductVariant.bidCode.id.bidCode;

        lineItems.push(item)
    });
    setRows(lineItems);
  }, [props.data]);

  const CurrencyFormatter = ({ value }) => (
    <b style={{ color: 'darkblue' }}>
      {value == null ? null : value.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
    </b>
  );  

  const CurrencyTypeProvider = props => (
    <DataTypeProvider
      formatterComponent={CurrencyFormatter}
      {...props}
    />
  );  

  const commitChanges = ({ added, changed }) => {
    let changedRows;
    if (added) {
      const startingAddedId = rows.length > 0 ? rows[rows.length - 1].id + 1 : 0;
      changedRows = [
        ...rows,
        ...added.map((row, index) => ({
          id: startingAddedId + index,
          ...row,
        })),
      ];
    }
    if (changed) {
      changedRows = rows.map(row => (changed[row.id] ? { ...row, ...changed[row.id] } : row));
    }
    setRows(changedRows);
  };

  return (
    <div style={{width: '100%'}}>
      <Paper>
        <Grid
          rows={rows}
          columns={columns}
          getRowId={getRowId}
        >
          <EditingState
            onCommitChanges={commitChanges}
          />
          <SortingState
            sorting={sorting}
            onSortingChange={setSorting}
          />
          <CurrencyTypeProvider
              for={["discount", "subtotal"]}
          />
          <IntegratedSorting />
          <Table cellComponent={FocusableCell} />
          <TableColumnResizing
            columnWidths={columnWidths}
            onColumnWidthsChange={setColumnWidths}
          />
          <TableHeaderRow showSortingControls/>
          <TableEditColumn
            showDeleteCommand
          />
        </Grid>
      </Paper>
    </div>
  );
};

