import axios from "axios";
import Cookies from 'js-cookie';

export const axiosPost = (url, json) => {
    const token = Cookies.get('JWT_TOKEN'); 
    const headers = { Authorization: `Bearer ${token}` }
    return axios.post(url, json, {
    //   withCredentials: true, // Include cookies in the request
      headers: headers
    }).then(response => {
        // Handle successful response
        return response.data; // Return the response data if needed
    }).catch(error => {
        // Handle error
        if (error.code === "ERR_NETWORK" || error.response && error.response.status === 401) {
            // Remove JWT token cookie
            Cookies.remove('JWT_TOKEN');
        }

        console.error('Error fetching bundle discounts:', error);
        throw error; // Optionally rethrow the error for further handling
    });
}

export const axiosGet = (url, json) => {
    const token = Cookies.get('JWT_TOKEN'); 
    // Set headers including Authorization Bearer token
    const headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json', // Assuming JSON content type
    };

    return axios.get(url, {
        ...json,
        headers: headers,
        // withCredentials: true, // Include cookies in the request
    }).then(response => {
        // Handle successful response
        return response.data; // Return the response data if needed
    }).catch(error => {
        // Handle error
        if (error.response && error.response.status === 401) {
            // Remove JWT token cookie
            Cookies.remove('JWT_TOKEN');
        }

        console.error('Error fetching bundle discounts:', error);
        throw error; // Optionally rethrow the error for further handling
    });
}