import { useState } from "react";
import { syncAllProducts, fetchAllProducts, deleteAllProducts, fetchAllVariantsByPage } from "../../service/products/products";
import ProductsOrderTable from "../ProductTable";
import "./Products.css"
import { useEffect } from "react";

function ProductsPageContent() {
    const [variants, setVariants] = useState([]);
    const [totalPages, setTotalPages] = useState(1);
    const [totalCount, setTotalCount] = useState(1);
    const [currentPage, setCurrentPage] = useState(0);
    const [pageSize, setPageSize] = useState(5);

    const syncAllProductsAPICall = async () => {
        try {
            const productsData = await syncAllProducts();

            let allVariants = [];
            productsData.forEach(product => {
                // Extract variants from the current product
                const variants = product.variants;
                variants.forEach(variant => {
                    variant.productName = product.title
                    variant.sold = variant.pendingCheckoutQuantity
                    variant.bidCode = variant.bidCode.id.bidCode;
                })
                
                // Concatenate the variants to the allVariants array
                allVariants = allVariants.concat(variants);
            });
            setVariants(allVariants)
        } catch (error) {
            // Handle error if needed
        }
    }

    const deleteAllProductsAPICall = async () => {
        try {
            await deleteAllProducts();
            setVariants([])
        } catch (error) {
            // Handle error if needed
        }
    }

    useEffect(() => {
        const syncAllProducts = async () => {
            try {
                const variantsPaginateData = await fetchAllVariantsByPage({shopName:"metaautomation.myshopify.com", page: currentPage, size: pageSize});
                const totalPagesResp = variantsPaginateData.totalPages;
                const currentPageResp = variantsPaginateData.pageable.pageNumber;
                const variantsData = variantsPaginateData.content;
                variantsData.forEach(variant => {
                    // Extract variants from the current product
                    variant.sold = variant.pendingCheckoutQuantity
                    variant.bidCode = variant.bidCode.id.bidCode;
                });
                setVariants(variantsData)
                if(totalPages !== totalPagesResp)
                    setTotalPages(totalPagesResp)
                if(currentPage !== currentPageResp)
                    setCurrentPage(currentPageResp)
                setTotalCount(variantsPaginateData.totalElements)
            } catch (error) {
                console.error('Error fetching products:', error);
                // Handle error if needed
            }
        }

        syncAllProducts()
    }, [currentPage]);

  return (
    <div>
        <div className="ProductsPageContent-Button-Row">
            <div className="ProductsPageContent-Button-Row-L">
            </div>
            <div className="ProductsPageContent-Button-Row-R">
                <div onClick={syncAllProductsAPICall}><label>Shopify Sync</label></div>
                {variants.length !== 0 ? <div onClick={deleteAllProductsAPICall} className="ProductsPageContent-Button-Row-R-Delete-btn"><label>Delete All</label></div> : <></>}
            </div>
        </div>
        <div className="ProductsPageContent-Table">
            <ProductsOrderTable data={variants}  totalCount={totalCount} totalPages={totalPages} currentPage={currentPage} pageSize={pageSize} setCurrentPage={setCurrentPage} setPageSize={setPageSize}/>
        </div>
    </div>
  );
}

export default ProductsPageContent;
