import React, { useState } from 'react';
import Paper from '@mui/material/Paper';
import {
  Grid,
  Table,
  TableHeaderRow,
  TableColumnResizing,
  TableEditColumn,
  PagingPanel
} from '@devexpress/dx-react-grid-material-ui';
import { CustomPaging, DataTypeProvider, EditingState, IntegratedPaging, IntegratedSorting, PagingState, SortingState } from '@devexpress/dx-react-grid';
import { useEffect } from 'react';
import { Plugin, Template, TemplateConnector, TemplatePlaceholder } from '@devexpress/dx-react-core';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle} from '@mui/material';
import VariantsTable from './VariantsTable';

const getRowId = row => row.id;

const FocusableCell = ({ onClick, ...restProps }) => (
  <Table.Cell {...restProps} tabIndex={0} onFocus={onClick} />
);

const Popup = ({
  row,
  onChange,
  onApplyChanges,
  onCancelChanges,
  open,
}) => (
  <Dialog open={open} onClose={onCancelChanges} aria-labelledby="form-dialog-title">
    <DialogTitle id="form-dialog-title">Order Summary</DialogTitle>
    <DialogContent>
      {row.id == null ? <></> : <VariantsTable data={row}/>}
    </DialogContent>
    <DialogActions>
      <Button onClick={onCancelChanges} color="secondary">
        Cancel
      </Button>
      <Button onClick={onApplyChanges} color="primary">
        Save
      </Button>
    </DialogActions>
  </Dialog>
);

const PopupEditing = React.memo(({ popupComponent: Popup }) => (
  <Plugin>
    <Template name="popupEditing">
      <TemplateConnector>
        {(
          {
            rows,
            getRowId,
            addedRows,
            editingRowIds,
            createRowChange,
            rowChanges,
          },
          {
            changeRow, changeAddedRow, commitChangedRows, commitAddedRows,
            stopEditRows, cancelAddedRows, cancelChangedRows,
          },
        ) => {
          const isNew = addedRows?.length > 0;
          let editedRow;
          let rowId;
          if (isNew) {
            rowId = 0;
            editedRow = addedRows[rowId];
          } else {
            [rowId] = editingRowIds;
            const targetRow = rows.filter(row => getRowId(row) === rowId)[0];
            editedRow = { ...targetRow, ...rowChanges[rowId] };
          }

          const processValueChange = ({ target: { name, value } }) => {
            const changeArgs = {
              rowId,
              change: createRowChange(editedRow, value, name),
            };
            if (isNew) {
              changeAddedRow(changeArgs);
            } else {
              changeRow(changeArgs);
            }
          };
          const rowIds = isNew ? [0] : editingRowIds;
          const applyChanges = () => {
            if (isNew) {
              commitAddedRows({ rowIds });
            } else {
              stopEditRows({ rowIds });
              commitChangedRows({ rowIds });
            }
          };
          const cancelChanges = () => {
            if (isNew) {
              // cancelAddedRows({ rowIds });
            } else {
              stopEditRows({ rowIds });
              // cancelChangedRows({ rowIds });
            }
          };

          const open = editingRowIds.length > 0 || isNew;
          return (
            <Popup
              open={open}
              row={editedRow}
              onChange={processValueChange}
              onApplyChanges={applyChanges}
              onCancelChanges={cancelChanges}
            />
          );
        }}
      </TemplateConnector>
    </Template>
    <Template name="root">
      <TemplatePlaceholder />
      <TemplatePlaceholder name="popupEditing" />
    </Template>
  </Plugin>
));

export default function OrdersTable(props){
  const [columns] = useState([
    { name: 'draftOrderName', title: 'Draft Order Name' },
    { name: 'buyerName', title: 'Buyer Name' },
    { name: 'invoiceUrl', title: 'Invoice URL' },
    { name: 'itemQuantity', title: 'Item Quantity' },
    { name: 'total', title: 'Total' },
    { name: 'discount', title: 'Total Discount' },
    { name: 'subtotal', title: 'SubTotal' },
  ]);
  const [columnWidths, setColumnWidths] = useState([
    { columnName: 'draftOrderName', width: 180 },
    { columnName: 'buyerName', width: 150 },
    { columnName: 'invoiceUrl', width: 180 },
    { columnName: 'itemQuantity', width: 180 },
    { columnName: 'discount', width: 180 },
    { columnName: 'total', width: 150 },
    { columnName: 'subtotal', width: 150 },
    { columnName: 'updated', width: 150 }
  ]);

  const [rows, setRows] = useState(props.data);
  const [sorting, setSorting] = useState([{ columnName: 'buyerName', direction: 'asc' }]);

  useEffect(() => {
    setRows(props.data);
  }, [props.data]);

  const CurrencyFormatter = ({ value }) => (
    <b style={{ color: 'darkblue' }}>
      {value == null ? null : value.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
    </b>
  );  

  const CurrencyTypeProvider = props => (
    <DataTypeProvider
      formatterComponent={CurrencyFormatter}
      {...props}
    />
  );  

  const commitChanges = ({ added, changed }) => {
    let changedRows;
    if (added) {
      const startingAddedId = rows.length > 0 ? rows[rows.length - 1].id + 1 : 0;
      changedRows = [
        ...rows,
        ...added.map((row, index) => ({
          id: startingAddedId + index,
          ...row,
        })),
      ];
    }
    if (changed) {
      changedRows = rows.map(row => (changed[row.id] ? { ...row, ...changed[row.id] } : row));
    }
    setRows(changedRows);
  };

  return (
    <div style={{width: '100%'}}>
      <Paper>
        <Grid
          rows={rows}
          columns={columns}
          getRowId={getRowId}
        >
          <PagingState
            currentPage={props.currentPage}
            onCurrentPageChange={props.setCurrentPage}
            pageSize={props.pageSize}
          />
          <CustomPaging
            totalCount={props.totalCount}
         />
          <EditingState
            onCommitChanges={commitChanges}
          />
          <SortingState
            sorting={sorting}
            onSortingChange={setSorting}
          />
          <CurrencyTypeProvider
              for={["total", "discount", "subtotal"]}
          />
          <IntegratedSorting />
          <Table cellComponent={FocusableCell} />
          <TableColumnResizing
            columnWidths={columnWidths}
            onColumnWidthsChange={setColumnWidths}
          />
          <TableHeaderRow showSortingControls/>
          <TableEditColumn
            // showAddCommand
            showEditCommand
          />
          <PopupEditing popupComponent={Popup} />
          <PagingPanel/>
        </Grid>
      </Paper>
    </div>
  );
};

