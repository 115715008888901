import axios from "axios";
import { axiosPost } from "../common";

export const fetchAllOrders = () => {
  const url = '/api/shopify/order/fetchAll';
  return axiosPost(url, {});
};

export const fetchAllOrdersByPage = (json) => {
  const url = '/api/shopify/order/fetchAllPage';
  return axiosPost(url, json);
};

export const syncAllOrders = () => {
  const url = '/api/shopify/order/syncShopifyOrders';
  return axiosPost(url, {});
}