import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import reportWebVitals from './reportWebVitals';
import HomePage from './components/home/HomePage';
import ProductsPage from './components/products/ProductsPage';
import PromotionsPage from './components/promotions/PromotionsPage';
import OrdersPage from './components/orders/OrdersPage';
import IntegrationPage from './components/integration/IntegrationPage';
import LoginPage from './components/authentication/LoginPage';
import PrivateRoute from './components/authentication/PrivateRoute';
import RegistrationPage from './components/authentication/RegistrationPage';
import BundleDiscountPage from './components/promotions/bundle/BundleDiscountPage';
import PrivacyPolicyPage from './components/policies/PrivacyPolicyPage';
import ComingSoonPage from './components/common/comingSoon/ComingSoonPage';

ReactDOM.render(
    <BrowserRouter>
      <Routes>
        {/* <Route path="/" element={<App />} /> */}
        <Route path="/login" element={<LoginPage />} />/
        <Route path="/register" element={<RegistrationPage />} />
        <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
        <Route
          path="*"
          element={
            <PrivateRoute>
              <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/analytics" element={<HomePage />} />
                <Route path="/products" element={<ProductsPage />} />
                <Route path="/promotions" element={<PromotionsPage />} />
                <Route path="/promotions/bundle" element={<BundleDiscountPage />} />
                <Route path="/orders" element={<OrdersPage />} />
                <Route path="/message-center" element={<ComingSoonPage />} />
                <Route path="/integration" element={<IntegrationPage />} />
                {/* <Route path="/landing" element={<LandingPage />} />
                <Route path="/home" element={<Home />} />
                <Route path="/invoices" element={<Invoices />} />
                <Route path="/subscribedProductsPage" element={<SubscribedProductPage />} /> */}
                <Route path="*" element={<Navigate to="/" />} />
              </Routes>
            </PrivateRoute>
          }
          />
        </Routes>
    </BrowserRouter>,

  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
