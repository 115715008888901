// import { Dialog, DialogContent, DialogContentText, DialogTitle, InputLabel } from "@mui/material";

import Footer from "../../common/Footer";
import Header from "../../common/Header";
import Sidebar from "../../common/Sidebar";
import BundleDiscountPageContent from "./BundleDiscountPageContent";

// export const BundleDiscountPage = () => {
//     <Dialog
//           open={open}
//           onClose={handleClose}
//           PaperProps={{
//             component: 'form',
//             onSubmit: (event) => {
//               event.preventDefault();
//               handleDiscountFormSubmission();
//               handleClose();
//             },
//           }}
//         >
//           <DialogTitle>Create new Discount</DialogTitle>
//           <DialogContent>
//             <DialogContentText style={{marginBottom: "30px"}}>
//               Fill in the following details accordingly
//             </DialogContentText>
//             {/* Title Field */}
//             <InputLabel id="type-select-label">Discount Title</InputLabel>
//             <FormControl fullWidth sx={{ m: 1 }} variant="filled">
//             <FilledInput
//                 id="filled-bundle-amount"
//                 onChange={handleDiscountTitleChange}
//                 value={discountTitle}
//               />
//             </FormControl>

//             {/* Type Field */}
//             <InputLabel id="type-select-label">Discount Applied To</InputLabel>
//             <FormControl fullWidth sx={{ m: 1 }} variant="filled">
//               <Select
//                 labelId="type-select-labe"
//                 id="type-select"
//                 value={discountAppliedTo}
//                 label="Type"
//                 onChange={handleProductTypeChange}
//               >
//                 <MenuItem value={"selectedProducts"}>Selected Products</MenuItem>
//                 <MenuItem value={"allProducts"}>All Products</MenuItem>
//                 <MenuItem value={"overallOrder"}>Overall Order</MenuItem>
//               </Select>
//             </FormControl>
            
//             <InputLabel id="type-select-label">Discount Type</InputLabel>
//             <FormControl fullWidth sx={{ m: 1 }} variant="filled">
//               <Select
//                 labelId="type-select-labe"
//                 id="type-select"
//                 value={discountType}
//                 label="Discount Type"
//                 onChange={handleDiscountTypeChange}
//               >
//                 <MenuItem value={"percentage"}>Percentage</MenuItem>
//                 <MenuItem value={"amount"}>Amount</MenuItem>
//                 <MenuItem value={"bundle"}>Bundle (X for $Y)</MenuItem>
//               </Select>
//             </FormControl>

//             <FormControl fullWidth sx={{ m: 1 }} variant="filled">
//               <InputLabel htmlFor="filled-adornment-amount">Discount Value</InputLabel>
//               <FilledInput
//                 id="filled-adornment-amount"
//                 onChange={handleDiscountValueChange}
//                 value={discountValue}
//                 startAdornment={<InputAdornment position="start">$</InputAdornment>}
//               />
//             </FormControl>

//             <FormControl sx={{ m: 1, width: '25ch' }} variant="filled">
//               <InputLabel htmlFor="filled-quantity-amount">Bundle Qty</InputLabel>
//               <FilledInput
//                 id="filled-quantity-amount"
//                 onChange={handleBundleQtyChange}
//                 value={bundleQty}
//               />
//             </FormControl>
//             <FormControl >
//               for
//             </FormControl>
//             <FormControl sx={{ m: 1, width: '25ch' }} variant="filled">
//               <InputLabel htmlFor="filled-bundle-amount">Bundle Price</InputLabel>
//               <FilledInput
//                 id="filled-bundle-amount"
//                 onChange={handleBundlePriceChange}
//                 value={bundlePrice}
//                 startAdornment={<InputAdornment position="start">$</InputAdornment>}
//               />
//             </FormControl>

//           </DialogContent>
//           <DialogActions>
//             <Button onClick={handleClose}>Cancel</Button>
//             <Button type="submit">Create Discount</Button>
//           </DialogActions>
//         </Dialog>
// }

function BundleDiscountPage() {

    return (
      <div className="App">
        <Header/>
        <div className='body'>
          <Sidebar/>
          <div className='subBody'>
              <BundleDiscountPageContent/>
          </div>
        </div>
        <Footer/>
      </div>
    );
  }
  
  export default BundleDiscountPage;