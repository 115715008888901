import React, { useState } from 'react';
import Paper from '@mui/material/Paper';
import { CustomPaging, DataTypeProvider, EditingState, PagingState } from '@devexpress/dx-react-grid';
import {
  Grid,
  Table,
  TableHeaderRow,
  TableEditColumn,
  TableColumnResizing,
  TableEditRow,
  PagingPanel,
} from '@devexpress/dx-react-grid-material-ui';
import { useEffect } from 'react';
import { updateBideCode } from '../service/products/products';

const getRowId = row => row.id;

const FocusableCell = ({ onClick, ...restProps }) => (
  <Table.Cell {...restProps} tabIndex={0} onFocus={onClick} />
);

export default function ProductsOrderTable(props){
  const [columns] = useState([
    { name: 'productName', title: 'Product Title' },
    { name: 'title', title: 'Variant' },
    { name: 'bidCode', title: 'Bid Code' },
    { name: 'price', title: 'Price' },
    { name: 'inventoryQuantity', title: 'Quantity' },
    { name: 'sold', title: 'Total Sold' },
  ]);
  const [columnWidths, setColumnWidths] = useState([
    { columnName: 'productName', width: 300 },
    { columnName: 'title', width: 150 },
    { columnName: 'bidCode', width: 180 },
    { columnName: 'price', width: 100 },
    { columnName: 'inventoryQuantity', width: 100 },
    { columnName: 'sold', width: 100 }
  ]);

  const [currencyColumns] = useState(['price']);
  const [rows, setRows] = useState(props.data);
  const [editingCells, setEditingCells] = useState([]);

  //Editing states
  const [editingRowIds, setEditingRowIds] = useState([]);
  const [addedRows, setAddedRows] = useState([]);
  const [rowChanges, setRowChanges] = useState({});
  const [editingStateColumnExtensions] = useState([
    { columnName: 'productName', editingEnabled: false },
    { columnName: 'title', editingEnabled: false },
    { columnName: 'bidCode', editingEnabled: true },
    { columnName: 'price', editingEnabled: false },
    { columnName: 'inventoryQuantity', editingEnabled: false },
    { columnName: 'sellableOnlineQuantity', editingEnabled: false },
    { columnName: 'sold', editingEnabled: false }
  ]);

  const changeAddedRows = (value) => {
    const initialized = value.map(row => (Object.keys(row).length ? row : { city: 'Tokio' }));
    setAddedRows(initialized);
  };


  // Use useEffect to update rows when props.data changes
  useEffect(() => {
    setRows(props.data);
  }, [props.data]);

  const commitChanges = ({ added, changed, deleted }) => {
    let changedRows;
    if (changed) {
      const ids = Object.keys(changed);
      var bidCodeChangeObj = {
        id: ids[0],
        bidCode: changed[ids[0]].bidCode
      }
      updateBidCodeAPI(bidCodeChangeObj)
      
      changedRows = rows.map(row => (changed[row.id] ? { ...row, ...changed[row.id] } : row));
    }
    setRows(changedRows);
  };
  

  const updateBidCodeAPI = async (json) => {
    await updateBideCode(json)
  }

  const CurrencyFormatter = ({ value }) => (
    <b style={{ color: 'darkblue' }}>
      {value.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
    </b>
  );  

  const CurrencyTypeProvider = props => (
    <DataTypeProvider
      formatterComponent={CurrencyFormatter}
      {...props}
    />
  );

  return (
    <div style={{width: '100%'}}>
      <Paper>
        <Grid
          rows={rows}
          columns={columns}
          getRowId={getRowId}
        >
          <PagingState
            currentPage={props.currentPage}
            onCurrentPageChange={props.setCurrentPage}
            pageSize={props.pageSize}
          />
          <CustomPaging
            totalCount={props.totalCount}
        />

          <EditingState
            editingRowIds={editingRowIds}
            onEditingRowIdsChange={setEditingRowIds}
            rowChanges={rowChanges}
            onRowChangesChange={setRowChanges}
            addedRows={addedRows}
            onAddedRowsChange={changeAddedRows}
            onCommitChanges={commitChanges}
            columnExtensions={editingStateColumnExtensions}
          />

          <CurrencyTypeProvider
              for={currencyColumns}
          />
          <Table cellComponent={FocusableCell} />
          <TableColumnResizing
            columnWidths={columnWidths}
            onColumnWidthsChange={setColumnWidths}
          />
          <TableHeaderRow />
          <TableEditRow />
          <TableEditColumn
            showEditCommand
          />
          <PagingPanel/>
        </Grid>
      </Paper>
    </div>
  );
};