import './Promotions.css';
import '@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css';
import PromotionsTable from './PromotionsTable';
import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FilledInput, FormControl, FormHelperText, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select, TextField } from '@mui/material';
import { createDiscount, fetchAllBundleDiscounts, fetchAllDiscounts } from '../../service/discount/discount';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';


function PromotionsPageContent() {

  const navigate = useNavigate();

  const [open, setOpen] = React.useState(false);
  const [discounts, setDiscounts] = React.useState([]);

  const [discountTitle, setDiscountTitle] = React.useState("");
  const [discountAppliedTo, setDiscountAppliedTo] = React.useState("");
  const [discountType, setdiscountType] = React.useState("");
  const [discountValue, setdiscountValue] = React.useState(0);
  const [bundleQty, setBundleQty] = React.useState(0);
  const [bundlePrice, setBundlePrice] = React.useState(0);

  const handleDiscountFormSubmission = async () => {
    try {
      var jsonObj = formUpDataForSubmission();
      await createDiscount(jsonObj);
    } catch (error) {
      console.error('Error creating discount:', error);
      // Handle error if needed
    }
  }

  const formUpDataForSubmission = () => {
    var jsonObject = {
      discountTitle: discountTitle,
      discountAppliedTo: discountAppliedTo,
      discountType: discountType,
      discountValue: discountValue,
      bundleQty: bundleQty,
      bundlePrice: bundlePrice
    };

    return jsonObject;
  }

  const handleDiscountTitleChange = (event) => {
    setDiscountTitle(event.target.value);
  };

  const handleProductTypeChange = (event) => {
    setDiscountAppliedTo(event.target.value);
  };

  const handleDiscountTypeChange = (event) => {
    setdiscountType(event.target.value);
  };

  const handleDiscountValueChange = (event) => {
    setdiscountValue(event.target.value);
  };

  const handleBundleQtyChange = (event) => {
    setBundleQty(event.target.value);
  };

  const handleBundlePriceChange = (event) => {
    setBundlePrice(event.target.value);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const fetchDiscounts = async () => {
        try {
            const discounts = await fetchAllBundleDiscounts();
            discounts.forEach(discount => {
              discount.variantCount = discount.variants.length
            });
            
            setDiscounts(discounts)
            console.log(discounts)
        } catch (error) {
            console.error('Error fetching products:', error);
            // Handle error if needed
        }
    }

    fetchDiscounts()
}, []);

  return (
    <div className="PromotionsPageContent">
        <div className='PromotionsPageContent-Header'>
            <div>
                <div>Bundle Discount</div>
            </div>
            <div className='PromotionsPageContent-Header-Buttons'>
                <div onClick={handleClickOpen}>Create Discount</div>
            </div>
        </div>
        <PromotionsTable data={discounts}/>
        <Dialog
          open={open}
          onClose={handleClose}
          PaperProps={{
            component: 'form',
            onSubmit: (event) => {
              event.preventDefault();
              handleDiscountFormSubmission();
              handleClose();
            },
          }}
        >
          <DialogTitle>Select Discount Type</DialogTitle>
          <DialogContent>
            <div className='discount-box' onClick={() => navigate('/promotions/bundle')}>
              <div>
                <div className='discount-title'>Buy X for $Y</div>
                <div className='discount-description'>Discount based on bundle purchase</div>
              </div>
              <div>
                &gt;
              </div>
            </div>
            <div className='discount-box'>
              <div>
                <div className='discount-title'>Amount off Order</div>
                <div className='discount-description'>Discount total order amount</div>
              </div>
              <div>
                &gt;
              </div>
            </div>
            
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
          </DialogActions>
        </Dialog>
    </div>
  );
}

export default PromotionsPageContent;
