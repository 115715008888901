import React from "react";
import "./Integration.css"
import { parseJwt } from "../../utilities/jwt";
import IntegratedTab from "./IntegratedTab";
import { useEffect } from "react";
import { checkShopifyIntegration } from "../../service/integration/shopify";


function IntegrationPageContent() {

    const [shopName, setShopName] = React.useState("");
    const [facebookId, setFacebookId] = React.useState("");
    const [shopifyUrl, setShopifyUrl] = React.useState("");
    const [shopifyIntegrated, setShopifyIntegrated] = React.useState(false);
    const [fieldsFilled, setFieldsFilled] = React.useState(false);

    const backendUrl = process.env.REACT_APP_BACKEND_URL_GATEWAY;

    const triggerShopifyOAuth2 = () => {
        const jwtDetails = parseJwt()
        let oauth2Url = `https://admin.shopify.com/store/metaautomation/oauth/authorize?client_id=a499590bc6d4cd272726c0b565530822&scope=write_products,read_shipping,write_draft_orders,read_draft_orders&redirect_uri=`+ backendUrl +`/shopify/authorization&state=${jwtDetails.attributes.id}&grant_options[]=per-user`
        // let oauth2Url = `https://admin.shopify.com/oauth/install_custom_app?client_id=a499590bc6d4cd272726c0b565530822&no_redirect=true&signature=eyJleHBpcmVzX2F0IjoxNzI0MDY1Mjc2LCJwZXJtYW5lbnRfZG9tYWluIjoic2hlcmt6eS1jby5teXNob3BpZnkuY29tIiwiY2xpZW50X2lkIjoiYTQ5OTU5MGJjNmQ0Y2QyNzI3MjZjMGI1NjU1MzA4MjIiLCJwdXJwb3NlIjoiY3VzdG9tX2FwcCIsIm1lcmNoYW50X29yZ2FuaXphdGlvbl9pZCI6MTE1MTUyNzR9--3d51900eb406bd7fb81dbe03205c4adb27d1bd8b&scope=write_products,read_shipping,write_draft_orders,read_draft_orders&redirect_uri=http://localhost:8888/shopify/authorization&state=${jwtDetails.attributes.id}&grant_options[]=per-user`
        // let shopName = "metaautomation"
        // let oauth2Url = "https://" + shopName + ".shopify.com/store/metaautomation/oauth/authorize?client_id=a499590bc6d4cd272726c0b565530822&scope=write_products,read_shipping,write_draft_orders,read_draft_orders&redirect_uri=http://localhost:8888/shopify/authorization&state=3321&grant_options[]=per-user"
        window.location.href = oauth2Url;
    }

    const onShopNameChange = (event) => {
        const value = event.target.value;
        setShopName(value);
        computeFieldsFilled(value, shopifyUrl);
    };

    const onShopifyUrlChange = (event) => {
        const value = event.target.value;
        setShopifyUrl(value);
        computeFieldsFilled(shopName, value);
    };
    
    const computeFieldsFilled = (name, url) => {
        if (name && url) {
            setFieldsFilled(true);
        } else {
            setFieldsFilled(false);
        }
    };

    useEffect(() => {
        const checkShopifyIntegrations = async () => {
            try {
                const checkIntegrationRes = await checkShopifyIntegration();
                if(checkIntegrationRes != null && checkIntegrationRes != ""){
                    setShopifyIntegrated(true);
                    setFacebookId(checkIntegrationRes.facebookId);
                    setShopName(checkIntegrationRes.shopName)
                }
            } catch (error) {
                console.error('Error fetching products:', error);
                // Handle error if needed
            }
        }

        checkShopifyIntegrations()
    }, []);

    return (
        <div className="IntegrationPageContent">
            <div className="IntegrationPageContent-Header">
                <div>
                    Your Stores
                </div>
            </div>
            <div>
                Our integration creates a seamless sync between Shopify and our Live platform.
            </div>
            {
                shopifyIntegrated ? 
                <IntegratedTab storeName={shopName} facebookId={facebookId}/>
                :
                <div className="IntegrationPageContent-MainBody-Register">
                    <div className="shopify-register-title">
                        Create New Shopify Integration
                    </div>
                    <div className="shopify-register-info">
                        1. Insert Your Shopify shop name and shop url
                    </div>
                    <div className="IntegrationPageContent-MainBody-Register-Details">
                        <div className="shopify-register-name">
                            <div>Shop Name</div>
                            <input type="text" value={shopName} onChange={onShopNameChange} placeholder="Shopify Shop Name"/>
                        </div>
                        <div className="shopify-register-url">
                            <div className="shopify-register-url-info">Your Shopify Store URL</div>
                            <div className="shopify-register-url-inputs">
                                <div className="shopify-register-url-inputs-init">https://</div>
                                <input onChange={onShopifyUrlChange} value={shopifyUrl}/>
                                <div className="shopify-register-url-inputs-tail">.myshopify.com</div>
                            </div>
                        </div>
                    </div>
                    <div className="IntegrationPageContent-integrate-btn-container">
                        <div 
                            className={`IntegrationPageContent-integrate-btn ${fieldsFilled ? 'active' : 'disabled'}`} 
                            onClick={fieldsFilled ? triggerShopifyOAuth2 : null}
                        >
                            Integrate
                        </div>
                    </div>
                </div>
                
            }
            
        </div>
    );
}

export default IntegrationPageContent;
