import Footer from "../Footer";
import Header from "../Header";
import Sidebar from "../Sidebar";
import ComingSoonContent from "./ComingSoonContent";

function ComingSoonPage() {

    return (
      <div className="App">
        <Header/>
        <div className='body'>
          <Sidebar/>
          <div className='subBody'>
              <ComingSoonContent/>
          </div>
        </div>
        <Footer/>
      </div>
    );
  }
  
  export default ComingSoonPage;