import "./Analytics.css"

function AnalyticsStatistics({ data }) {

    return (
      <div className="AnalyticsStatistics">
        <div className="AnalyticsStatistics-Main">
            <div>
                {data.title}
            </div>
            <div className="AnalyticsStatistics-Main-Data">
                {data.mainData}
            </div>
        </div>
      </div>
    );
  }
  
  export default AnalyticsStatistics;