import axios from "axios";
import { axiosPost } from "../common";

export const syncAllProducts = () => {
  const url = '/api/shopify/product/syncAll';
  return axiosPost(url, {});
}

export const deleteAllProducts = () => {
  const url = '/api/shopify/product/deleteAll';
  return axiosPost(url, {});
}

export const fetchAllProducts = () => {
  const url = '/api/shopify/product/fetchAll';
  return axiosPost(url, {});
};

export const updateBideCode = (json) => {
  const url = '/api/shopify/product/updateBidCode';
  return axiosPost(url, json);
}

export const fetchAllVariantsByPage = (json) => {
  const url = '/api/shopify/variants/fetchAll';
  return axiosPost(url, json);
};