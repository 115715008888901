import './BundleDiscount.css';
import '@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css';
import React from 'react';
import { Autocomplete, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { fetchAllProducts } from '../../../service/products/products';
import SelectedProduct from './SelectedProduct';
import { createBundleDiscount, createDiscount } from '../../../service/discount/discount';


function BundleDiscountPageContent() {

  const navigate = useNavigate();

  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [itemsText, setItemsText] = React.useState("Buy any");
  const [products, setProducts] = React.useState([]);
  const [selectedProducts, setSelectedProducts] = React.useState([]);

  const [discountTitle, setDiscountTitle] = React.useState("");
  const [bundleQty, setBundleQty] = React.useState();
  const [bundlePrice, setBundlePrice] = React.useState();

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const handleDiscountFormSubmission = async (navigate) => {
    try {
      var jsonObj = formUpDataForSubmission();
      await createBundleDiscount(jsonObj);
    } catch (error) {
      console.error('Error creating discount:', error);
      // Handle error if needed
    }

    navigate("/promotions")
  }

  const formUpDataForSubmission = () => {
    var variants = [];

    selectedProducts.forEach(product => {
        variants.push(...product.variants)
    })

    var jsonObject = {
      discountTitle: discountTitle,
      bundleQty: bundleQty,
      bundlePrice: bundlePrice,
      variants
    };

    return jsonObject;
  }

  const handleCloseAutocomplete = (event, newValue) => {
    setSelectedProducts(newValue);
    console.log(newValue)
  };

  const handleClose = () => {
    setDialogOpen(false);
  };

  useEffect(() => {
    const syncAllProducts = async () => {
        try {
            const productsData = await fetchAllProducts();

            let allVariants = [];
                productsData.forEach(product => {
                    // Extract variants from the current product
                    const variants = product.variants;
                    variants.forEach(variant => {
                        variant.sold = variant.pendingCheckoutQuantity
                        variant.bidCode = variant.bidCode.id.bidCode;
                    })
                    
                    // Concatenate the variants to the allVariants array
                    allVariants = allVariants.concat(variants);
                });
                setProducts(productsData)
        } catch (error) {
            console.error('Error fetching products:', error);
            // Handle error if needed
        }
    }

    syncAllProducts()
}, []);

  return (
    <div className="BundleDiscountPageContent">
        <div className='discount-header-container'>
            <div style={{fontWeight:"bold", marginBottom: "10px"}}>Bundle Information</div>
            <div>
                *Bundle Name
            </div>
            <input placeholder='Input' value={discountTitle} onChange={(event) => setDiscountTitle(event.target.value)}/>
        </div>

        <div className='discount-body-container'>
            <div className='discount-body-container-title'>
                <div>Discount Details</div>
            </div>
            <div className='discount-body-container-conditions'>
                <div>Condition</div>
                <div className='discount-body-container-radio-btns'>
                    <input type="radio" id="minSpent" name="fav_language" value="HTML"/>
                    <label for="minSpent">Minimum Spent</label><br></br>

                    <input type="radio" id="minQty" name="fav_language" value="HTML"/>
                    <label for="minQty">Minimum Qty</label><br></br>

                    <input type="radio" id="bundle" name="fav_language" value="HTML"/>
                    <label for="bundle">Quantity</label><br></br>
                </div>
                
            </div>
            <div className='discount-inputContainer'>
                <div>{itemsText}</div>
                <div className='discount-inputContainer-input'>
                    <input onChange={(event) => {setBundleQty(event.target.value)}} value={bundleQty}/><div className='discount-inputContainer-input-item'>items</div>
                </div>
                <div>at</div>
                <div className='discount-inputContainer-input'>
                    <div className='discount-inputContainer-input-currency'>S$</div><input value={bundlePrice} onChange={(event) => {setBundlePrice(event.target.value)}}/>
                </div>
            </div>

            <div className='discount-selectProductContainer'>
                <input placeholder='Search products' onClick={() => setDialogOpen(true)}/>
            </div>
            <div className='selectedProduct-main-container'>
                <div className='selectedProduct-container'>
                    {selectedProducts.map((prod, index) => (
                        <SelectedProduct key={index} product={prod} />
                    ))}
                </div>
            </div>
            <div className='discount-body-footer'>
                <div className='discount-body-footer-btn-container'>
                    <div onClick={() => navigate("/promotions")} className='discount-body-footer-btn-discard'>
                        Discard
                    </div>
                    <div onClick={() => handleDiscountFormSubmission(navigate)} className='discount-body-footer-btn-save'>
                        Save Discount
                    </div>
                </div>
            </div>
            
            
            <Dialog open={dialogOpen} onClose={"test"} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Add Products</DialogTitle>
                <DialogContent>
                <Autocomplete
                    multiple
                    id="product-selection"
                    options={products}
                    disableCloseOnSelect
                    onChange={handleCloseAutocomplete}
                    getOptionLabel={(option) => option.title}
                    renderOption={(props, option, { selected }) => {
                        const { key, ...optionProps } = props;
                        return (
                        <li key={key} {...optionProps}>
                            <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                            />
                            {option.title}
                        </li>
                        );
                    }}
                    style={{ width: 500 }}
                    renderInput={(params) => (
                        <TextField {...params} placeholder="Search products"/>
                    )}
                />

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={handleClose} color="primary">
                        Add
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    </div>
  );
}

export default BundleDiscountPageContent;
