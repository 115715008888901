import { useNavigate } from "react-router-dom";
import "./RegistrationPage.css"


function RegistrationPage() {

    const navigate = useNavigate();

    return (
        <div className="mainregistration">
            <div className="registration-window">
                <div className="registration-window-logo">Muezza</div>
                <div className="registration-window-details">
                    <div className="registration-window-details-registration">Create a Muezza Account</div>
                    <div>One last step before starting your free trial</div>
                </div>
                <div className="registration-details-container">
                    <div onClick={() => navigate('/')} className="registration-details-container-facebook">
                        <img src={`${process.env.PUBLIC_URL}/assets/images/fb_logo.png`} alt="Facebook" />
                        <label>Sign up with Facebook</label>
                    </div>
                </div>
                <div className="registration-details-separator">
                    <div/>
                </div>
                <div className="registration-window-login">
                    <div>Already have a Muezza account? <a className="registration-window-registration-link" href="/login">Log In -&gt;</a></div>
                </div>
                <div className="registration-window-terms">
                    By proceeding, you agree to the <a href="/">Terms and Conditions</a> and <a href="/">Privacy Policy</a>
                </div>
                <div className="registration-window-links">
                    <div>
                        <a href="/">Help</a>
                    </div>
                    <div>
                        <a href="/">Privacy</a>
                    </div>
                    <div>
                        <a href="/">Terms</a>
                    </div>
                    
                </div>
            </div>
        </div>
    );
}

export default RegistrationPage;
