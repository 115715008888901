import { useNavigate } from "react-router-dom";
import "./LoginPage.css"
import axios from 'axios';


function LoginPage() {

    const backendUrl = process.env.REACT_APP_BACKEND_URL_AUTH;

    const executeLoginHandler = async () => {
        var redirectUrl = backendUrl + '/oauth2/authorization/facebook';
        window.location.href = redirectUrl;
    }

    return (
        <div className="mainLogin">
            <div className="login-window">
                <div className="login-window-logo">Muezza</div>
                <div className="login-window-details">
                    <div className="login-window-details-login">Log in</div>
                    <div>Continue to Muezza</div>
                </div>
                <div className="login-details-container">
                    <div onClick={() => executeLoginHandler()} className="login-details-container-facebook">
                        <img src={`${process.env.PUBLIC_URL}/assets/images/fb_logo.png`} alt="Facebook" />
                    </div>
                </div>
                <div className="login-details-separator">
                    <div/>
                </div>
                <div className="login-window-registration">
                    <div>New to Muezza? <a className="login-window-registration-link" href="/register">Get Started -&gt;</a></div>
                </div>
                <div className="login-window-links">
                    <div>
                        <a href="/">Help</a>
                    </div>
                    <div>
                        <a href="/">Privacy</a>
                    </div>
                    <div>
                        <a href="/">Terms</a>
                    </div>
                    
                </div>
            </div>
        </div>
    );
}

export default LoginPage;
